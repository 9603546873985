import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";
import log from "../utils/logger";

const initialState = {
  Auth: {
    pending: false,
    success: null,
    error: null,
  },
};

export const GetTempUserAccessToken = createAsyncThunk(
  "tempuser/login",
  async (sessionId) => {
    try {
      log.debug("sessionId: ", sessionId);
      const response = await Api.get(`/tempuser/login?session_id=${sessionId}`);
      log.debug("response from tempuser login", response);
      return response;
    } catch (error) {
      const { status, data } = error.response;
      return {
        error: {
          type: "server",
          message: data.detail,
        },
      };
    }
  }
);

const Auth = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    clearAccessToken: (state) => {
      state.Auth.success = null;
      state.Auth.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTempUserAccessToken.pending, (state) => {
        state.Auth.pending = true;
        state.Auth.success = null;
        state.Auth.error = null;
      })
      .addCase(GetTempUserAccessToken.fulfilled, (state, action) => {
        state.Auth.pending = false;
        state.Auth.success = action.payload;
        state.Auth.error = null;
      })
      .addCase(GetTempUserAccessToken.rejected, (state, action) => {
        state.Auth.pending = false;
        state.Auth.success = null;
        state.Auth.error = action.payload;
      });
  },
});

export const { clearAccessToken } = Auth.actions;
export default Auth.reducer;
