import React from "react";
import { IoMdClose } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import log from "../../utils/logger";

function ChatImageModal({ onClose, image }) {
  log.debug("In desc: ", image);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    adaptivewidth: true,
  };

  return (
    <>
      <div className="main-popup image-modal">
        <div className="lm-outer">
          <div className="lm-inner">
            <div className="popup-inner">
              <div className="popup-header">
                <div className="popup-heading">
                  <div className="popup-title">
                    <h3 className="opacity-0">Image Modal</h3>
                  </div>
                  <div className="close-pop-icon">
                    <span onClick={onClose}>
                      <IoMdClose size={30} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="popup-body">
                <div className="image-item">
                  <div className="modal-image-item">
                    <Slider {...settings}>
                      {image.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`Product ${index + 1}`}
                            className="w-full h-auto"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay" onClick={onClose}></div>
      </div>
    </>
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  prevArrow: (
    <button className="text-white bg-gradient-to-r from-blue-600 to-teal-400 rounded-full shadow-lg cursor-pointer h-[35px] w-[35px] flex items-center justify-center text-[25px] font-semibold">
      &lt;
    </button>
  ),
  nextArrow: (
    <button className="text-white bg-gradient-to-r from-blue-600 to-teal-400 rounded-full shadow-lg cursor-pointer h-[35px] w-[35px] flex items-center justify-center text-[25px] font-semibold">
      &gt;
    </button>
  ),
};

export default ChatImageModal;

{
  /* <div
className=" fixed inset-0 z-9 bg-[#0000000f] bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
style={{ zIndex: 99 }}
>
<div className="mt-10 flex flex-col gap-5 text-white">
  <button className="place-self-end" onClick={onClose}>
    <IoMdClose size={30} />
  </button>
  <div className="bg-[#ffff] rounded-lg rounded-xl px-20 py-10 flex flex-col gap-5 items-center mx-4 text-center">
    <img
      src={product.images[0]}
      alt="Product"
      className="w-full h-auto"
    />
    <div className="flex justify-between mt-4">
      <button className="text-gray-500">&lt;</button>
      <button className="text-gray-500">&gt;</button>
    </div>
  </div>
</div>
</div> */
}
