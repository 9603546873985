import React, { useEffect, useRef, useState } from "react";
import { FaImage } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import { NoImage } from "../../../assets/images";

const Product = (props, index) => {
  // Fallback image URL if the passed image is undefined or null
  const backgroundImage = props.img ? `url(${props.img})` : `url(${NoImage})`;

  const [currentItem, setCurrentItem] = useState(null);
  const selectRefs = useRef([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        currentItem !== null &&
        selectRefs.current[currentItem] &&
        !selectRefs.current[currentItem].contains(event.target)
      ) {
        setCurrentItem(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentItem]);

  const onItemClicked = (index) => {
    if (currentItem === index) {
      setCurrentItem(null);
    } else {
      setCurrentItem(index);
    }
  };

  return (
    <div
      className="w-full relative group rounded-lg overflow-hidden"
      style={{
        boxShadow: "0 1px 7px 0 #0000001c, inset 0 2px 6px 0 #00000014",
      }}
    >
      <div className="max-w-80 h-[300px] bg-[#fafafa] relative overflow-hidden">
        <div
          className="bg-image"
          style={{
            backgroundImage: backgroundImage,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        ></div>
        {/* <div
          className="kebab-main"
          ref={(currentDiv) => (selectRefs.current[index] = currentDiv)}
        > */}
        {/* <div className="kebab-icon" onClick={() => onItemClicked(index)}> */}
        <div className="kebab-main">
          {props.img && (
            <div className="kebab-icon" onClick={props.onImageClick}>
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H16V2H2V16ZM3 14H15L11.25 9L8.25 13L6 10L3 14Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          )}
          {/* {currentItem === index && (
            <div className="kebab-dropdown">
              <ul>
                <li>
                  <a onClick={props.onImageClick}>View Image</a>
                </li>
               <li>
                  <a onClick={props.onDescriptionClick}>View Details</a>
                </li> 
              </ul>
            </div>
          )} */}
        </div>
      </div>
      <div className="p-4 flex items-end justify-between">
        <h2 className="text-lg text-primeColor font-bold">
          {props.productName}
        </h2>
        <a onClick={props.onDescriptionClick} className="viewdtl-btn">
          View Details
          <em>
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.775 20L0 18.225L8.225 10L0 1.775L1.775 0L11.775 10L1.775 20Z"
                fill="#3a9be7"
              />
            </svg>
          </em>
        </a>
      </div>
    </div>
  );
};

export default Product;
