import log from "loglevel";

if (process.env.REACT_APP_MODE === "development") {
  console.log("mode: ", process.env.REACT_APP_MODE);
  log.setLevel("debug"); // Ensure this is set for development
} else {
  log.setLevel("silent"); // Suppress logs in production
}

export default log;
