import "./style.css";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
];
