import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

const initialState = {
  ProductDetails: { pending: false, success: null, error: null },
  BusinessId: { pending: false, success: null, error: null },
};

export const GetBusinessId = createAsyncThunk(
  "biz/id",
  async (business_username) => {
    try {
      // log.debug("business_username: ", business_username);
      const response = await Api.get(
        `/biz/id?business_username=${business_username}`
      );
      // log.debug("response", response);
      return response;
    } catch (error) {
      const { status, data } = error.response;
      return {
        error: {
          type: "server",
          message: data.detail,
        },
      };
    }
  }
);

export const GetProductDetails = createAsyncThunk(
  "inventory/catalog",
  async ({ business_id, offset }) => {
    // log.debug("Offset: ", offset);
    try {
      // log.debug("business_id: ", business_id);
      const details = await Api.get(
        `/inventory/catalog?business_id=${business_id}&offset=${offset}`
      );
      return details;
    } catch (error) {
      const { status, data } = error.response;
      return {
        error: {
          type: "server",
          message: data.detail,
        },
      };
    }
  }
);

const Product = createSlice({
  name: "Product",
  initialState,
  reducers: {
    clearProductDetails: (state) => {
      state.ProductDetails.pending = false;
      state.ProductDetails.success = null;
      state.ProductDetails.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetProductDetails.pending, (state) => {
        state.ProductDetails.pending = true;
        state.ProductDetails.success = null;
        state.ProductDetails.error = null;
      })
      .addCase(GetProductDetails.fulfilled, (state, action) => {
        state.ProductDetails.pending = false;
        state.ProductDetails.success = action.payload;
        state.ProductDetails.error = null;
      })
      .addCase(GetProductDetails.rejected, (state, action) => {
        state.ProductDetails.pending = false;
        state.ProductDetails.success = null;
        state.ProductDetails.error = action.error;
      })
      .addCase(GetBusinessId.pending, (state) => {
        state.BusinessId.pending = true;
        state.BusinessId.success = null;
        state.BusinessId.error = null;
      })
      .addCase(GetBusinessId.fulfilled, (state, action) => {
        state.BusinessId.pending = false;
        state.BusinessId.success = action.payload;
        state.BusinessId.error = null;
      })
      .addCase(GetBusinessId.rejected, (state, action) => {
        state.BusinessId.pending = false;
        state.BusinessId.success = null;
        state.BusinessId.error = action.error;
      });
  },
});

export const { clearProductDetails } = Product.actions;
export default Product.reducer;
