import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { GetChatDetails } from "../../redux/ChatSlice";
import ChatImageModal from "./ChatImageModal";
import log from "../../utils/logger";

function ChatModal({ onClose, socket, businessId }) {
  log.debug("businessId: ", businessId);
  const [message, setMessage] = useState("");
  const [chatId, setChatId] = useState(null);
  const [ChatList, setChatlist] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const ChatDetails = useSelector((state) => state.Chat.ChatDetails);

  useEffect(() => {
    log.debug("socket in ChatModal: ", socket);
    if (localStorage.getItem(`chatId_${businessId}`)) {
      setChatId(+localStorage.getItem(`chatId_${businessId}`));
    }
    // return () => {
    //   setChatId(null);
    // };
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      log.debug("WebSocket message received:", event.data);
      const data = JSON.parse(event.data);
      if (data?.system_message) {
        setChatId(data?.chat_id);
        localStorage.setItem(`chatId_${businessId}`, data?.chat_id);
      }
      if (data?.message_details) {
        setChatlist((prev) => [data, ...prev]);
      }
    };

    if (socket) {
      socket.current.addEventListener("message", handleMessage);
    }

    return () => {
      if (socket) {
        socket.current.removeEventListener("message", handleMessage);
      }
    };
  }, [socket, ChatList]);

  useEffect(() => {
    let storedChatID = localStorage.getItem(`chatId_${businessId}`);
    if (storedChatID) {
      dispatch(GetChatDetails({ chat_id: storedChatID, offset: 0 }));
    }
  }, []);

  useEffect(() => {
    if (ChatDetails?.success) {
      const { chat_details } = ChatDetails.success;
      log.debug("chat_details", chat_details);
      setChatlist((prev) => [...chat_details, ...prev]);
    }
  }, [ChatDetails?.success]);

  const sendMessage = (message) => {
    if (socket) {
      const messageObject = {
        role: "owner",
        user_id: businessId,
        chat_id: chatId,
        message,
      };

      const messageString = JSON.stringify(messageObject);
      log.debug("messageString: ", messageString);
      socket.current.send(messageString);
      setMessage("");
    }
  };

  const send = () => {
    let msg = {
      master_id: "",
      sender_type: "user",
      is_read: false,
      sent_time: new Date().getTime(),
      id: null,
      sender_id: businessId,
      message_details: {
        type: "text",
        contents: message,
      },
      read_time: null,
      is_ai_message: false,
    };
    if (message) {
      sendMessage({ type: "text", contents: message });
      setMessage("");
      setChatlist((prev) => [msg, ...prev]);
    }
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      send();
    }
  };

  const openImageModal = (imageUrl) => {
    log.debug("imageUrl: ", imageUrl);
    if (imageUrl.length > 0) {
      setSelectedImage(imageUrl);
      setImageModal(true);
    }
  };

  return (
    <div className="min-h-screen antialiased text-gray-800 z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center p-5">
      <div className="flex flex-col h-full w-full md:w-2/3 lg:w-1/2 bg-white overflow-hidden rounded-2xl shadow-xl">
        <div className="flex items-center justify-end p-4 border-b border-[#ededed]">
          <button className="place-self-end" onClick={onClose}>
            <IoMdClose size={30} />
          </button>
        </div>
        <div className="flex flex-col flex-grow h-full overflow-y-scroll px-4 py-4">
          <ul className="flex flex-col-reverse gap-2">
            {ChatList.map((msg, index) => (
              <li key={index}>
                {msg.sender_type === "assistant" ? (
                  <div className="flex items-center">
                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-[#defffa] text-[#33E2C7] flex-shrink-0">
                      A
                    </div>
                    <div className="relative ml-3 text-sm bg-[#33E2C7] py-2 px-4 shadow rounded-xl">
                      <div className="text-[#000E08]">
                        {msg.message_details.contents}
                      </div>
                      {msg.message_details.images &&
                        Object.keys(msg.message_details.images).map((key) => (
                          <li
                            key={key}
                            style={{
                              marginBottom: "22px",
                              gap: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* {log.debug(
                                      key,
                                      message_details.images[key]
                                    )} */}
                            <strong>{key}:</strong>
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openImageModal(msg.message_details.images[key])
                              }
                            >
                              View Image
                            </p>
                            {/* {message_details.images[key]} */}
                            {imageModal && (
                              <ChatImageModal
                                image={selectedImage}
                                onClose={() => setImageModal(false)}
                              />
                            )}
                          </li>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-start flex-row-reverse">
                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-[#1371EF] text-[#ffffff] flex-shrink-0">
                      A
                    </div>
                    <div className="relative mr-3 text-sm bg-[#DFF6FF] py-2 px-4 shadow rounded-xl">
                      <div className="text-[#1371EF]">
                        {msg.message_details.contents}
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center h-16 bg-white w-full px-4 py-4 border-t border-[#ededed]">
          <input
            type="text"
            onChange={handleInputChange}
            value={message}
            onKeyDown={handleEnter}
            className="flex-grow border rounded-xl focus:outline-none focus:border-indigo-300 px-4 h-10"
          />
          <div className="ml-2" onClick={send}>
            <div className="send-icon bg-[#33e2c7] flex items-center justify-center h-10 w-10 rounded-xl">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.7601 1.04317C20.5763 0.85942 20.3467 0.728007 20.0952 0.662469C19.8436 0.596931 19.5792 0.599638 19.329 0.67031H19.3153L1.77438 5.99272C1.48885 6.07483 1.2351 6.24198 1.04694 6.47191C0.85877 6.70184 0.745111 6.98363 0.721095 7.27977C0.697078 7.5759 0.763844 7.87233 0.912501 8.12957C1.06116 8.38681 1.28465 8.59267 1.55322 8.71972L9.37782 12.4255L13.0836 20.2501C13.2006 20.5007 13.3868 20.7125 13.6203 20.8607C13.8538 21.0089 14.1248 21.0871 14.4014 21.0863C14.4434 21.0863 14.4855 21.0844 14.5275 21.0808C14.8226 21.0569 15.1033 20.9433 15.332 20.7553C15.5607 20.5673 15.7264 20.3138 15.8069 20.0289L21.1257 2.48801C21.1257 2.48344 21.1257 2.47887 21.1257 2.4743C21.1973 2.22478 21.2011 1.9607 21.1369 1.70918C21.0727 1.45767 20.9426 1.22779 20.7601 1.04317ZM14.4096 19.6104L14.405 19.6232L10.808 12.0298L15.1252 7.71172C15.2565 7.57349 15.3286 7.38944 15.3262 7.19879C15.3237 7.00815 15.2469 6.826 15.1121 6.69119C14.9773 6.55637 14.7951 6.47955 14.6045 6.47711C14.4139 6.47467 14.2298 6.5468 14.0916 6.67812L9.77353 10.9953L2.17923 7.39826H2.19202L19.7256 2.07768L14.4096 19.6104Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatModal;
