import React from "react";
import { IoMdClose } from "react-icons/io";

function DescriptionModal({ onClose, product }) {
  return (
    <>
      <div className="main-popup service-type-modal">
        <div className="lm-outer">
          <div className="lm-inner">
            <div className="popup-inner">
              <div className="popup-header">
                <div className="popup-heading">
                  <div className="popup-title">
                    <h3>Product Detail</h3>
                  </div>
                  <div className="close-pop-icon">
                    <span onClick={onClose}>
                      <IoMdClose size={30} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="popup-body">
                <div class="detail-data">
                  <p>Name</p>
                  <h4>{product.product_name}</h4>
                </div>
                <div class="detail-data">
                  <p>Category</p>
                  <h4>{product.category_name}</h4>
                </div>
                <div className="more-list">
                  <ul>
                    {Object.keys(product.features).map((key) => (
                      <li key={key}>
                        <div class="detail-data">
                          <p>{key}</p>
                          <h4>{product.features[key]}</h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay" onClick={onClose}></div>
      </div>
    </>
  );
}

export default DescriptionModal;

{
  /* <div
      className="fixed inset-0  z-9 text-zinc-700 bg-[#0000000f] bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
      style={{ zIndex: 99 }}
    >
      <div className="relative  max-h-[80vh] w-full max-w-4xl overflow-y-auto p-6 mx-4 my-8 rounded-lg">
        <button className="absolute top-7 right-7" onClick={onClose}>
          <IoMdClose size={30} />
        </button>
        <div
          className="bg-[#ffff] rounded-lg py-8"
          style={{ boxShadow: "0 16px 26px #0000001a" }}
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row -mx-4">
              <div className="md:flex-1 px-4 gap-2">
                <div className="flex items-center">
                  <h2 className="text-lg font-bold mb-2 flex flex-row gap-4">
                    Name:
                    <span className="text-zinc-500">
                      {product.product_name}
                    </span>
                  </h2>
                </div>
                <div className="flex items-center">
                  <h2 className="text-lg font-bold mb-2 flex flex-row gap-4">
                    Category:
                    <span className="text-zinc-500">
                      {product.category_name}
                    </span>
                  </h2>
                </div>
                <div>
                  {Object.keys(product.features).map((key) => (
                    <li key={key}>
                      <strong>{key}:</strong> {product.features[key]}
                    </li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */
}
