import CryptoJS from "crypto-js";
import axios from "axios";
import log from "./logger";

export const setToken = (accessToken, refreshToken) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const businessName = pathSegments[2];
  log.debug("businessName in setToken: ", businessName);
  localStorage.setItem(`accessToken_${businessName}`, accessToken);
  localStorage.setItem(`refreshToken_${businessName}`, refreshToken);
};

// export const setSessionToken = (sessionId, businessName) => {
//   localStorage.setItem(`sessionId_${businessName}`, sessionId);
// };

export const checkToken = (businessName) => {
  log.debug("businessName in checkToken: ", businessName);
  if (localStorage.getItem(`accessToken_${businessName}`)) {
    const access_token = localStorage.getItem(`accessToken_${businessName}`);
    const refresh_token = localStorage.getItem(`refreshToken_${businessName}`);
    return {
      access_token: access_token,
      refresh_token: refresh_token,
    };
  } else {
    return {
      access_token: null,
      refresh_token: null,
    };
  }
};

// export const checkSessionId = (businessName) => {
//   if (localStorage.getItem(`sessionId_${businessName}`)) {
//     const session_id = localStorage.getItem(`sessionId_${businessName}`);
//     return { session_id: session_id };
//   } else {
//     return {
//       session_id: null,
//     };
//   }
// };

export const refreshAccessToken = async (businessName) => {
  try {
    const refreshToken = localStorage.getItem(`refreshToken_${businessName}`);
    log.debug("refreshToken: ", refreshToken);
    const response = await axios.post(
      process.env.REACT_APP_BASEURL + "/auth/refresh/",
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const newAccessToken = response.data.access_token;
    const newRefreshToken = response.data.refresh_token;
    log.debug("Setting new access token and refresh token: ", newAccessToken);
    setToken(newAccessToken, newRefreshToken);
    window.location.reload();
    return newAccessToken;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    throw error;
  }
};

export const clearToken = () => {
  localStorage.clear();
  // window.location.href = "/login";
};
