import axios from "axios";
import {
  checkToken,
  refreshAccessToken,
  clearToken,
} from "../utils/tokenHandler";
import log from "../utils/logger";

log.debug("BASE URL: ", process.env.REACT_APP_BASEURL);
const baseURL = process.env.REACT_APP_BASEURL;
const instance = axios.create({
  baseURL,
  headers: {
    "ngrok-skip-browser-warning": "69420",
    "X-Pinggy-No-Screen": "true",
  },
});

// Function to add the access token to the headers
const addAccessToken = () => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const businessName = pathSegments[2];
  log.debug("businessName in addAccessToken: ", businessName);
  const access_token = checkToken(businessName);
  log.debug(access_token);
  instance.defaults.headers.common["Authorization"] = `Bearer ${
    access_token.access_token ? access_token.access_token : ""
  }`;
};

addAccessToken();

// Axios interceptor for handling 401 responses
const interceptor = instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    log.debug("error");
    log.debug("error");
    log.debug(error);
    if (error.code === "ERR_NETWORK") {
      // CORS error, handle as needed
      // For example, display a user-friendly message
      // toast.error('CORS error occurred. Please check your network connection or CORS settings.');
      clearToken();
      throw error;
    }
    const { status } = error.response;
    if (status === 401 && !error.config._isRetry) {
      try {
        // Attempt to refresh the access token
        // addAccessToken();
        const pathname = window.location.pathname;
        const pathSegments = pathname.split("/");
        const businessName = pathSegments[2];
        log.debug("businessName: ", businessName);
        await refreshAccessToken(businessName);
        addAccessToken(); // Update headers with the new access token
        // Retry the original request after token refresh
        error.config._isRetry = true;
        return instance(error.config);
      } catch (refreshError) {
        if (refreshError.response && refreshError.response.status === 401) {
          clearToken(); // Clear the token on refresh failure
          throw refreshError; // Propagate the error to the catch block below
        }
      }
    }
    throw error; // Propagate other errors to the catch block below
  }
);

// Function to remove the interceptor when it's no longer needed
const removeInterceptor = () => {
  instance.interceptors.response.eject(interceptor);
};

// Add the access token to headers
addAccessToken();

const Api = {
  get: async (url, params) => {
    try {
      const response = await instance.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  post: async (url, data, params) => {
    try {
      const response = await instance.post(url, data, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  put: async (url, data, params) => {
    try {
      const response = await instance.put(url, data, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url, params) => {
    try {
      const response = await instance.delete(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  removeInterceptor, // Expose the removeInterceptor function for cleanup
};

export default Api;
