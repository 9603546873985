import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
const Shop = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Products" />
      <div className="w-full h-full flex pb-10 gap-10">
        <div className="w-full mdl:w-[100%] lgl:w-[100%] h-full flex flex-col gap-5">
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default Shop;
