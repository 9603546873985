import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";
import log from "../utils/logger";

const initialState = {
  ChatDetails: {
    pending: false,
    success: null,
    error: null,
  },
};

export const GetChatDetails = createAsyncThunk(
  "/chat/getChatDetails",
  async ({ chat_id, offset }) => {
    try {
      log.debug("offset: ", offset, "chat_id: ", chat_id);
      const response = await Api.get(
        `/chat?chat_id=${chat_id}&offset=${offset}`
      );
      log.debug("response from getChatDetails", response);
      return response;
    } catch (error) {
      const { status, data } = error.response;
      return {
        error: {
          type: "server",
          message: data.detail,
        },
      };
    }
  }
);

const Chat = createSlice({
  name: "Chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetChatDetails.pending, (state) => {
        state.ChatDetails.pending = true;
        state.ChatDetails.success = null;
        state.ChatDetails.error = null;
      })
      .addCase(GetChatDetails.fulfilled, (state, action) => {
        state.ChatDetails.pending = false;
        state.ChatDetails.success = action.payload;
        state.ChatDetails.error = null;
      })
      .addCase(GetChatDetails.rejected, (state, action) => {
        state.ChatDetails.pending = false;
        state.ChatDetails.success = null;
        state.ChatDetails.error = action.payload;
      });
  },
});

export default Chat.reducer;
